<template>
  <div :class="{ 'illustrated-bkg': $route.name === 'login' }">
    <GlobalLoader />
    <ShyLoader />
    <Toast />
    <Modals />
    <ChatNotification />
    <router-view v-slot="{ Component }">
      <transition name="slide-fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script setup>
import ApiService from '@/api/new/services/api.service'
import Toast from '@/components/shared/Toast'
import ChatNotification from '@/components/shared/chat/ChatNotification'
import GlobalLoader from '@/components/shared/loaders/GlobalLoader.vue'
import ShyLoader from '@/components/shared/loaders/ShyLoader.vue'
import Modals from '@/components/shared/modals/Modals'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import moment from 'moment'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { locale } = useI18n()

const registration = ref(null)
const updateExists = ref(true)
const refreshing = ref(false)

const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

watch(
  () => route.params.locale,
  async (l) => {
    if (i18n.availableLocales.includes(l) && l !== locale.value) {
      locale.value = l
      moment.locale(l)
      $cookies.set('locale', l)
      router.replace({ params: { locale: locale.value } })
    }
  }
)
watch(
  () => route.query.theme,
  (theme) => {
    if (theme) document.documentElement.setAttribute('data-theme', theme)
  }
)

const setRefreshPage = (payload) =>
  store.commit('workspace/setRefreshPage', payload)

function refreshApp() {
  updateExists.value = false
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration.value || !registration.value.waiting) return
  // Send message to SW to skip the waiting and activate the new SW
  registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
}
function updateAvailable(event) {
  registration.value = event.detail
  updateExists.value = true
  refreshApp()
}

onBeforeMount(() => {
  document.addEventListener('swUpdated', updateAvailable, { once: true })
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    // We'll also need to add 'refreshing' to our data originally set to false.
    if (refreshing.value) return
    refreshing.value = true
    // Here the actual reload of the page occurs
    setRefreshPage(true)
  })
})

onMounted(() => {
  const env = $cookies.get('env')
  if (isAuthenticated.value && env == 'sandbox') {
    ApiService.init(process.env.VUE_APP_ROOT_API_SANDBOX)
  } else {
    $cookies.remove('env')
    ApiService.init(process.env.VUE_APP_ROOT_API)
  }
})
</script>

<style lang="scss">
.snackbar[role='alertdialog'] {
  background-color: #377bdd !important;
  .text {
    color: black !important;
  }
  .action {
    .button {
      background: black !important;
    }
  }
}
</style>
